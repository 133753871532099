import { FC, useState } from "react";
import { StepButtons, Flex, Skeleton, ExtendedCard } from "components";
import { StepInstructions } from "../shared";
import { hooks, Types } from "../../duck";
import { formatToCurrency } from "utils";

const Documents: FC<Types.StepProps> = ({ company, onNextClick, ...props }) => {
  const [distributionID, setDistributionID] = useState(company.distributionID);
  const { data, isLoading } = hooks.useDocumentDistribution();

  return (
    <>
      <StepInstructions
        currentStep={props.currentStep}
        title="company.sixStep.title"
        description="company.sixStep.description"
      />
      <Flex gap={14} justify="space-around" wrap="wrap">
        {isLoading ? (
          <>
            <Flex vertical gap={14}>
              <Skeleton.Image
                active
                style={{ width: "100%", height: "150px" }}
              />
              <Skeleton.Input active style={{ width: "auto" }} />
            </Flex>
            <Flex vertical gap={14}>
              <Skeleton.Image
                active
                style={{ width: "100%", height: "150px" }}
              />
              <Skeleton.Input active style={{ width: "auto" }} />
            </Flex>
            <Flex vertical gap={14}>
              <Skeleton.Image
                active
                style={{ width: "100%", height: "150px" }}
              />
              <Skeleton.Input active style={{ width: "auto" }} />
            </Flex>
          </>
        ) : (
          data?.map((item) => (
            <ExtendedCard
              key={item.id}
              title={item.type}
              description={`${item.description} ${formatToCurrency(
                item.price,
              )}`}
              selected={item.id === distributionID}
              onClick={() => setDistributionID(item.id)}
              hoverable={item.id !== distributionID}
            />
          ))
        )}
      </Flex>
      <StepButtons
        {...props}
        onNextClick={() => {
          onNextClick(
            {
              distributionID,
            },
            distributionID !== company.distributionID,
          );
        }}
      />
    </>
  );
};

export default Documents;
