import { FC, useState } from "react";
import {
  FormattedMessage,
  AsyncSelect,
  Form,
  InputDatePicker,
  InputText,
  InputEmail,
  Button,
  Drawer,
  Address,
  FormInstance,
  AddressSelect,
  Typography,
} from "components";
import { Country, Individual } from "types";
import { getQueryKey } from "utils";
import { useMutation, useSelector } from "hooks";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  formItem: {
    width: "85%",
  },
});

interface IndividualDrawerProps {
  open: boolean;
  parentForm: FormInstance;
  onClose: () => void;
}

const AddIndividualDrawer: FC<IndividualDrawerProps> = ({
  open,
  parentForm,
  onClose,
}) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const clientID = useSelector((state) => state.auth.client?.id as number);
  const [childDrawerOpen, toggleChildDrawer] = useState(false);
  const { isPending, mutate } = useMutation<Individual>({
    apiName: "client",
    method: "post",
    path: `/${clientID}/individual`,
    invalidateQueries: [getQueryKey("clientIndividuals", clientID)],
    onSuccess: (newData) => {
      onClose();

      parentForm.setFields([
        {
          name: "individualID",
          value: newData.id,
          touched: true,
        },
      ]);

      form.resetFields();
    },
  });

  const countryQueryKey = getQueryKey("countries");
  return (
    <Drawer
      open={open}
      width={500}
      destroyOnClose
      onClose={onClose}
      title={<FormattedMessage id="company.createIndividual" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Address
        clientID={clientID}
        drawerProps={{
          open: childDrawerOpen,
          onClose: () => toggleChildDrawer(false),
        }}
        onSuccess={(newData) => {
          toggleChildDrawer(false);

          form.setFields([
            {
              name: "addressID",
              value: newData.id,
              touched: true,
            },
          ]);
        }}
      />
      <Form layout="vertical" form={form} onFinish={mutate}>
        <Typography.Paragraph>
          Use this form to create a new <strong>Individual</strong> record.
        </Typography.Paragraph>
        <InputText required name="firstName" label="common.first_name" />
        <InputText name="middleName" label="common.middle_name" />
        <InputText required name="lastName" label="common.last_name" />
        <InputEmail />
        <AddressSelect
          name="addressID"
          justify="space-between"
          className={classes.formItem}
          onCreate={() => {
            toggleChildDrawer(true);
          }}
        />
        <InputDatePicker
          label="common.dateOfBirth"
          name="dateOfBirth"
          isAfterToday
          required
        />
        <InputText
          label="common.placeOfBirthCity"
          name="placeOfBirthCity"
          required
        />
        <AsyncSelect<Country>
          required
          label="common.placeOfBirthCountry"
          name="placeOfBirthCountryID"
          queryKey={countryQueryKey}
          apiName="system"
          path="/countries"
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.full_name,
          })}
        />
        <AsyncSelect<Country>
          label="common.nationality"
          name="nationalityID"
          queryKey={countryQueryKey}
          apiName="system"
          path="/countries"
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.full_name,
          })}
        />
      </Form>
    </Drawer>
  );
};

export default AddIndividualDrawer;
