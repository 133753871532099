import { FC, useState, ReactElement, useEffect } from "react";
import { ExtendedSteps, Alert } from "components";
import * as LC from "./components";
import { constants, Types } from "./duck";
import { getQueryKey } from "utils";
import { routes } from "configs";
import { viewHOC } from "hocs";
import { useMutation } from "hooks";
import { Company, CompanyStatus } from "types";

const steps = constants.STEP_TITLES;

const ViewCompany: FC<Types.HocProps> = ({ record, getTopics, queryKey }) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (record.name) {
      getTopics({
        extra: <strong>{record.name}</strong>,
      });
    }
  }, [record.name, getTopics]);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const mutation = useMutation<Company, Partial<Company>>({
    apiName: "companies",
    method: "patch",
    invalidateQueries: [
      getQueryKey("companyList"),
      getQueryKey("company", record.id),
    ],
    onSuccess: () => {
      nextStep();
    },
    path: routes.company.updateStep({
      // 1, 2, 6, 7 steps
      id: record.id,
      step: currentStep + 1,
    }),
  });

  const COMPANY_STATUS: Record<number, ReactElement> = {
    [CompanyStatus.Pending]: (
      <Alert
        type="success"
        message="Company Application has been received by ASIC and is in process"
      />
    ),
    [CompanyStatus.Submitted]: (
      <Alert type="success" message="Company Application has been submitted" />
    ),
    [CompanyStatus.Registered]: <LC.CompanyTabs company={record} />,
    [CompanyStatus.Deregistered]: <LC.CompanyTabs company={record} />,
  };

  const element = COMPANY_STATUS[record.statusID];

  if (element) {
    return element;
  }

  const commonProps = {
    company: record,
    loading: mutation.isPending,
    steps,
    currentStep,
    onNextClick: async (values?: Partial<Company>, touched?: boolean) => {
      if (!values || !touched) {
        nextStep();

        return;
      }

      mutation.mutate(values);
    },
    onPrevClick: () => {
      setCurrentStep(currentStep - 1);
    },
  };

  const stepComponents = [
    <LC.Company {...commonProps} />,
    <LC.Address {...commonProps} />,
    <LC.Officeholders {...commonProps} />,
    <LC.ShareCapital {...commonProps} />,
    <LC.Shareholders {...commonProps} />,
    <LC.Documents {...commonProps} />,
    <LC.Options {...commonProps} />,
    <LC.Confirm {...commonProps} companyQueryKey={queryKey} />,
  ];

  return (
    <>
      <ExtendedSteps steps={steps} currentStep={currentStep} />
      {stepComponents[currentStep]}
    </>
  );
};

export default viewHOC({
  Component: ViewCompany,
  skeleton: <LC.Skeleton />,
  queryKeyFn: (params) => getQueryKey("company", params.id),
  apiName: "companies",
  pathFn: ({ params }) => `/${params.id}`,
});
