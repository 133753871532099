import { FC, useState } from "react";
import {
  Drawer,
  Form,
  Button,
  Icons,
  AsyncSelect,
  InputSelect,
  Flex,
  InputGroup,
  FormItemInputNumber,
  Typography,
  Divider,
} from "components";
import { CreateCompanyDrawer } from "./components";
import { AddIndividualDrawer, IndividualSelect } from "../../../shared";
import { useMutation, useQuery, useSelector } from "hooks";
import { getQueryKey } from "utils";
import { createUseStyles } from "react-jss";
import {
  Company,
  ShareCapital,
  ShareholderType,
  Shareholder,
  CompanyStatus,
} from "types";
import { FormattedMessage } from "react-intl";

const useStyles = createUseStyles({
  companySelect: {
    width: "85%",
  },
  flex: {
    marginBottom: 24,
  },
});

interface AddNewDrawerProps {
  companyID: number;
  onClose: () => void;
  open: boolean;
  queryKey: string[];
  shareholders: Shareholder[];
}

const AddNewDrawer: FC<AddNewDrawerProps> = ({
  open,
  companyID,
  onClose,
  queryKey,
  shareholders,
}) => {
  const [companyDrawerOpen, toggleCompanyDrawer] = useState(false);
  const [individualDrawerOpen, toggleIndividualDrawer] = useState(false);
  const [form] = Form.useForm();
  const classes = useStyles();
  const clientID = useSelector((state) => state.auth.client?.id);

  const companyListQueryKey = getQueryKey("companyList", clientID);
  const shareCapitalQueryKey = getQueryKey("companyShareCapital", companyID);
  const { isPending, mutate } = useMutation({
    method: "post",
    apiName: "companies",
    path: `/${companyID}/shareholders`,
    invalidateQueries: [queryKey, shareCapitalQueryKey],
    onSuccess: () => {
      onClose();
      form.resetFields();
    },
  });

  const { data: shareCapitalList = [], isLoading } = useQuery<ShareCapital[]>({
    apiName: "companies",
    path: `/${companyID}/share-capital`,
    queryKey: shareCapitalQueryKey,
    select: (data) =>
      data.reduce((prev, curr) => {
        const sharesIssued = shareholders
          .filter((item) => item.shareClassID === curr.shareClassID)
          .reduce(
            (result, shareholder) => result + shareholder.sharesIssued,
            0,
          );

        return [
          ...prev,
          {
            ...curr,
            quantity: curr.quantity - sharesIssued,
          },
        ];
      }, [] as ShareCapital[]),
  });

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title={<FormattedMessage id="company.fiveStep.addShareholder" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <AddIndividualDrawer
        parentForm={form}
        open={individualDrawerOpen}
        onClose={() => toggleIndividualDrawer(false)}
      />
      <CreateCompanyDrawer
        companyListQueryKey={companyListQueryKey}
        parentForm={form}
        open={companyDrawerOpen}
        onClose={() => toggleCompanyDrawer(false)}
      />
      <Form
        form={form}
        layout="vertical"
        onFinish={mutate}
        onValuesChange={(values) => {
          const [name] = Object.keys(values);

          if (name === "shareClassID") {
            form.validateFields(["sharesIssued"]);
          }
        }}
        initialValues={{
          beneficiallyHeld: true,
        }}
      >
        <Typography.Title level={5}>1. Select Shareholder</Typography.Title>
        <Typography.Paragraph>
          Select if your Shareholder is an Individual or a Company.
        </Typography.Paragraph>
        <AsyncSelect<ShareholderType>
          required
          label="common.type"
          name="typeID"
          queryKey={getQueryKey("shareholdersType")}
          apiName="system"
          path="/shareholder-types"
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.type,
          })}
        />
        <Form.Item dependencies={["typeID"]} noStyle>
          {({ getFieldValue }) => {
            const value = getFieldValue("typeID");

            if (!value) {
              return null;
            }

            if (value === 1) {
              return (
                <>
                  <Typography.Paragraph>
                    Select an existing individual from the list below, or press
                    &quot;+&quot; to add a new individual.
                  </Typography.Paragraph>
                  <IndividualSelect
                    onAddClick={() => toggleIndividualDrawer(true)}
                  />
                </>
              );
            }

            return (
              <>
                <Typography.Paragraph>
                  Select an existing company from the list below, or press
                  &quot;+&quot; to add a new company.
                </Typography.Paragraph>
                <Flex
                  justify="space-between"
                  align="center"
                  gap={8}
                  className={classes.flex}
                >
                  <AsyncSelect<Company>
                    required
                    preserve={false}
                    name="companyID"
                    className={classes.companySelect}
                    label="common.company"
                    apiName="companies"
                    select={(data) =>
                      data.filter(
                        (item) => item.statusID === CompanyStatus.Registered,
                      )
                    }
                    queryKey={companyListQueryKey}
                    getOptionProps={(opt) => ({
                      value: opt.id,
                      children: `${opt.name} ${opt.acn || ""}`,
                    })}
                  />
                  <Form.Item label={<p hidden>click</p>}>
                    <Button
                      icon={<Icons.PlusOutlined />}
                      onClick={() => toggleCompanyDrawer(true)}
                    />
                  </Form.Item>
                </Flex>
              </>
            );
          }}
        </Form.Item>
        <Divider />
        <Typography.Title level={5}>2. Beneficially Held?</Typography.Title>
        <InputGroup
          optionType="default"
          name="beneficiallyHeld"
          label="company.fiveStep.beneficiallyHeld"
        />
        <Divider />
        <Typography.Title level={5}>3. Allocate the Shares</Typography.Title>
        <InputSelect
          required
          loading={isLoading}
          options={shareCapitalList}
          label="company.shareClass"
          name="shareClassID"
          getOptionProps={(opt) => ({
            value: opt.shareClassID,
            disabled: !opt.quantity,
            children: (
              <>
                {opt.shareCode} (<FormattedMessage id="common.quantity" />:{" "}
                {opt.quantity})
              </>
            ),
          })}
        />
        <Form.Item dependencies={["shareClassID"]} noStyle>
          {({ getFieldValue }) => {
            const value = getFieldValue("shareClassID");
            const shareCapital = shareCapitalList.find(
              (item) => item.shareClassID === value,
            );

            return (
              <FormItemInputNumber
                name="sharesIssued"
                label="company.fiveStep.numberOfSharesIssued"
                greaterThan={0}
                lessThan={shareCapital?.quantity}
                required
              />
            );
          }}
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddNewDrawer;
