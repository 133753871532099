import {
  Button,
  Row,
  Col,
  Divider,
  Image,
  NavLink,
  Typography,
  FormattedMessage,
  CompanyTable,
} from "components";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { dashboardLogo } from "assets";

const DashboardPage = () => {
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col {...COMMON_COL_PROPS}>
          <Image src={dashboardLogo} preview={false} />
        </Col>
        <Col {...COMMON_COL_PROPS}>
          <Typography.Title level={2}>
            <FormattedMessage id="dashboard.welcome" />
          </Typography.Title>
          <Divider />
          <Typography.Paragraph>
            <FormattedMessage
              id="dashboard.description"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <FormattedMessage
              id="dashboard.subDescription"
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography.Paragraph>
          <Divider />
          <NavLink to="/companies/create">
            <Button style={{ width: "300px", marginTop: 4 }} type="primary">
              <strong>Create New Company</strong> - $647.00
            </Button>
          </NavLink>
          <br />
          <NavLink to="/companies/create">
            <Button
              style={{ width: "300px", marginTop: 4 }}
              // type="primary"
              disabled
            >
              <strong>Setup New Trust</strong> - $coming soon
            </Button>
          </NavLink>
          <br />
          <NavLink to="/companies/create">
            <Button
              style={{ width: "300px", marginTop: 4 }}
              // type="primary"
              disabled
            >
              <strong>Setup New SMSF</strong> - $coming soon
            </Button>
          </NavLink>
        </Col>
      </Row>
      <CompanyTable />
    </>
  );
};

export default DashboardPage;
