import { Col, Row } from "antd";
import { ROW_GUTTER } from "consts";
import InputText from "components/input-text";
import { AsyncSelect } from "components/input-select";
import { CommonRecord, Country } from "types";
import { getQueryKey } from "utils";

const DrawerLayout = () => {
  return (
    <>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={12}>
          <InputText label="address.unitNumber" name="unitNumber" />
        </Col>
        <Col xs={24} sm={12}>
          <InputText label="address.streetNumber" name="streetNumber" />
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={16}>
          <InputText label="address.streetName" name="streetName" required />
        </Col>
        <Col xs={24} sm={8}>
          <AsyncSelect<CommonRecord>
            required
            label="address.streetType"
            name="streetTypeID"
            apiName="system"
            path="/street-types"
            queryKey={getQueryKey("streetTypes")}
            getOptionProps={(opt) => ({
              value: opt.id,
              children: opt.name,
            })}
          />
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={16}>
          <InputText required label="address.suburb" name="suburb" />
        </Col>
      </Row>
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} sm={12}>
          <InputText required label="address.state" name="state" />
        </Col>
        <Col xs={24} sm={12}>
          <InputText required label="address.postcode" name="postcode" />
        </Col>
      </Row>
      <AsyncSelect<Country>
        required
        name="countryID"
        label="common.country"
        apiName="system"
        path="/countries"
        queryKey={getQueryKey("countries")}
        getOptionProps={(opt) => ({
          value: opt.id,
          children: opt.full_name,
        })}
      />

      {/* <AsyncSelect<CommonRecord>
                label="address.boxType"
                name="boxTypeID"
                apiName="system"
                path="/box-types"
                queryKey={getQueryKey("boxTypes")}
                getOptionProps={(opt) => ({
                    value: opt.id,
                    children: opt.name,
                })}
            />
            <InputText label="address.boxNumber" name="boxNumber" /> */}
    </>
  );
};

export default DrawerLayout;
