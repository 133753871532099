import { FC, useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Form, notification, StepButtons } from "components";
import { Types } from "../../../../duck";
import TotalPrice from "../total-price";

interface CompletePaymentProps extends Omit<Types.StepProps, "company"> {
  totalPrice: number;
}

const CompletePayment: FC<CompletePaymentProps> = ({
  totalPrice,
  ...btnProps
}) => {
  const [loading, toggleLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  return (
    <Form
      layout="vertical"
      onFinish={async () => {
        if (!stripe || !elements) {
          notification.error({
            message: "Payment system error",
          });
          return;
        }

        toggleLoading(true);

        try {
          const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: window.location.href,
            },
          });

          if (error) {
            notification.error({
              message: error.message,
            });
          }
        } catch (e: any) {
          notification.error({
            message: e.message,
          });
        }

        toggleLoading(false);
      }}
    >
      <TotalPrice total={totalPrice} />
      <PaymentElement />

      <StepButtons
        {...btnProps}
        loading={loading}
        nextDisabled={!stripe || !elements}
      />
    </Form>
  );
};

export default CompletePayment;
