import { getQueryKey } from "../utils";
import { useQuery } from "./tanstack";
import { ShareCapital } from "../types";

const useCompanyShareCapital = (companyID: number) => {
  const queryKey = getQueryKey("companyShareCapital", companyID);

  const { data = [], isLoading } = useQuery<ShareCapital[]>({
    apiName: "companies",
    path: `/${companyID}/share-capital`,
    queryKey,
  });

  return {
    data,
    isLoading,
    queryKey,
  };
};

export default useCompanyShareCapital;
