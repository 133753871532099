import { FC } from "react";
import { ShareholdersTable, ShareCapitalTable } from "../../../shared";
import { useCompanyHolders, useCompanyShareCapital } from "hooks";
import { TabProps } from "../../types";

const ShareHoldings: FC<TabProps> = ({ company }) => {
  const { data = [], isLoading } = useCompanyHolders({
    companyID: company.id,
    subRoute: "shareholders",
  });
  const { data: shareCapital, isLoading: shareCapitalLoading } =
    useCompanyShareCapital(company.id);

  return (
    <>
      <ShareholdersTable showTitle isLoading={isLoading} data={data} />
      <ShareCapitalTable
        showTitle
        isLoading={shareCapitalLoading}
        data={shareCapital}
      />
    </>
  );
};

export default ShareHoldings;
