import { FC } from "react";
import {
  FormattedMessage,
  AsyncSelect,
  Form,
  InputText,
  Button,
  Drawer,
  FormInstance,
  InputGroup,
} from "components";
import { CompanyType, Company, Jurisdiction, QueryKey } from "types";
import { getQueryKey } from "utils";
import { useMutation } from "hooks";
import { routes } from "configs";

interface CompanyDrawerProps {
  open: boolean;
  parentForm: FormInstance;
  onClose: () => void;
  companyListQueryKey: QueryKey;
}

const AddCompanyDrawer: FC<CompanyDrawerProps> = ({
  open,
  parentForm,
  onClose,
  companyListQueryKey,
}) => {
  const [form] = Form.useForm();
  const { isPending, mutate } = useMutation<Company>({
    apiName: "companies",
    path: "/registered",
    method: "post",
    invalidateQueries: [companyListQueryKey],
    onSuccess: (newData) => {
      onClose();

      parentForm.setFields([
        {
          name: "companyID",
          value: newData.id,
          touched: true,
        },
      ]);

      form.resetFields();
    },
  });

  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title={<FormattedMessage id="company.add" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          mutate(values);
        }}
      >
        <InputText required name="name" label="company.name" />
        <InputText required name="acn" label="company.acn" />
        <AsyncSelect<CompanyType>
          required
          name="typeID"
          label="company.type"
          queryKey={getQueryKey("companyTypes")}
          apiName="companies"
          path={routes.company.types()}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.description,
          })}
        />
        <AsyncSelect<Jurisdiction>
          required
          name="jurisdictionID"
          label="common.jurisdiction"
          apiName="system"
          path="/jurisdictions"
          queryKey={getQueryKey("jurisdictions")}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: opt.name,
          })}
        />
        <InputGroup
          optionType="default"
          name="selfManagedSuperFund"
          label="company.firstStep.selfManagedSuperFund"
        />
      </Form>
    </Drawer>
  );
};

export default AddCompanyDrawer;
