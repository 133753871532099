import { FC } from "react";
import { Tabs } from "components";
import * as LC from "./components";
import { TabProps } from "./types";

const CompanyTabs: FC<TabProps> = ({ company }) => {
  return (
    <Tabs
      items={[
        {
          label: "Summary",
          key: "Summary",
          children: <LC.Summary company={company} />,
        },
        {
          label: "Address",
          key: "Address",
          children: <LC.Address company={company} />,
        },
        {
          label: "Documents",
          key: "Documents",
          children: <LC.Documents companyID={company.id} />,
        },
        {
          label: "Officeholders",
          key: "Officeholders",
          children: <LC.Officeholders company={company} />,
        },
        {
          label: "Shareholders",
          key: "Shareholders",
          children: <LC.ShareHoldings company={company} />,
        },
      ]}
    />
  );
};

export default CompanyTabs;
