import { FC, useState } from "react";
import { ROW_GUTTER, COMMON_COL_PROPS } from "consts";
import { useSelector } from "hooks";
import {
  StepButtons,
  Form,
  FormattedMessage,
  Row,
  Col,
  Typography,
  AddressSelect,
  Address,
  Modal,
} from "components";
import { StepInstructions } from "../shared";
import { Types } from "../../duck";
import { ModalType } from "./types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  addressSelect: {
    maxWidth: "90%",
  },
  form: {
    minHeight: 300,
  },
});

const AddressStep: FC<Types.StepProps> = ({
  onNextClick,
  company,
  ...props
}) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const clientID = useSelector((state) => state.auth.client?.id as number);
  const [modal, toggleModal] = useState<ModalType>(null);

  const registeredOffice = (
    <FormattedMessage id="company.secondStep.registeredOffice" />
  );
  const principalPlaceOfBusiness = (
    <FormattedMessage id="company.secondStep.principalPlaceOfBusiness" />
  );

  return (
    <>
      <Modal
        open={!!modal}
        destroyOnClose
        onCancel={() => toggleModal(null)}
        footer={null}
      >
        <Address
          clientID={clientID}
          onSuccess={(newData) => {
            form.setFields([
              {
                name: modal,
                value: newData.id,
                touched: true,
              },
            ]);
            toggleModal(null);
          }}
        />
      </Modal>
      <Form
        className={classes.form}
        form={form}
        layout="vertical"
        initialValues={company}
        onFinish={async (values) => {
          onNextClick(values, form.isFieldsTouched());
        }}
      >
        <StepInstructions
          currentStep={props.currentStep}
          title="company.secondStep.title"
          description="company.secondStep.description"
          descriptionValues={{
            registeredOffice,
            principalPlaceOfBusiness,
          }}
        />
        <Row gutter={ROW_GUTTER}>
          <Col {...COMMON_COL_PROPS}>
            <Typography.Title level={4}>{registeredOffice}</Typography.Title>
            <AddressSelect
              className={classes.addressSelect}
              name="registeredOfficeID"
              onCreate={() => toggleModal("registeredOfficeID")}
            />
          </Col>
          <Col {...COMMON_COL_PROPS}>
            <Typography.Title level={4}>
              {principalPlaceOfBusiness}
            </Typography.Title>
            <AddressSelect
              className={classes.addressSelect}
              name="principalPlaceOfBusinessID"
              onCreate={() => toggleModal("principalPlaceOfBusinessID")}
            />
          </Col>
        </Row>
        <StepButtons {...props} />
      </Form>
    </>
  );
};

export default AddressStep;
