import { useQuery } from "../tanstack";
import { getQueryKey } from "utils";
import { Args, OfficeholdersDataSource, ShareholdersDataSource } from "./types";

const useCompanyHolders = <SubRoute extends "officeholders" | "shareholders">({
  companyID,
  subRoute,
}: Args<SubRoute>) => {
  const queryKey = getQueryKey(subRoute, companyID);

  const { data = [], isLoading } = useQuery<
    SubRoute extends "officeholders"
      ? OfficeholdersDataSource[]
      : ShareholdersDataSource[]
  >({
    apiName: "companies",
    path: `/${companyID}/${subRoute}`,
    queryKey,
  });

  return {
    data,
    isLoading,
    queryKey,
  };
};

export default useCompanyHolders;
