import { ENDPOINTS } from "consts/endpoints";
import { TableColumnType, Breakpoint } from "antd";
import { ReactElement, ReactNode } from "react";
import { QueryKey } from "@tanstack/react-query";

export type { Params } from "react-router-dom";
export type { QueryKey };

export type ApiNames = keyof typeof ENDPOINTS;

export { CognitoUser } from "amazon-cognito-identity-js";

export type Language = "en" | "zh";

export type { TableColumnType };

export type BreakpointMap = Record<Breakpoint, boolean>;

export enum OfficeholderType {
  Director = 1,
  Secretary = 2,
  PublicOfficer,
}

export type AccountSlug = "pimwacorp";

export enum AccountSlugs {
  "pimwacorp" = "pimwacorp",
}

export interface Officeholder {
  id: number;
  ceaseDate: Date | null;
  companyID: number | null;
  individualID: number | null;
  residentAddressID: number;
  startDate: Date;
  typeID: number;
}

export interface Shareholder extends Officeholder {
  beneficiallyHeld: number;
  shareClassID: number;
  sharesIssued: number;
}

export interface ExtendedDescriptionItem {
  label: string;
  translate?: boolean;
  value: ReactNode;
}

export interface ViewHOCChildProps<TRecord> {
  record: TRecord;
  queryKey: QueryKey;
}

export enum ShareClassEnum {
  ORD = 1,
  PREF = 2,
  A_CLASS = 3,
  B_CLASS = 4,
}

export interface ShareCapital {
  companyID: number;
  id: number;
  price: number;
  quantity: number;
  shareClassID: number;
  shareClass: string;
  shareCode: string;
}

export enum CompanyStatus {
  Draft = 1,
  Submitted = 2,
  Pending = 3,
  Registered = 4,
  Deregistered = 5,
}

export interface ShareClass {
  code: string;
  description: string;
  id: number;
}

export interface ShareholderType {
  id: number;
  type: string;
}

export interface Phone {
  clientID: number | null;
  home: string | null;
  id: number;
  mobile: string | null;
  work: string | null;
}

export interface DashboardHOCState {
  title?: string;
  subTitle?: string;
  extra?: any;
}

export interface DashboardHOCChildProps {
  getTopics(args: DashboardHOCState): void;
}

export interface CompanyDocumentDistribution {
  description: string;
  id: number;
  type: string;
  price: number;
}

export interface Individual extends Omit<Personal, "id"> {
  clientID: number | null;
  deleted: number;
  id: number;
  residentAddressID: number;
}

export enum ColumnFilterInputTypes {
  DATE_RANGE = "DATE_RANGE",
  INPUT = "INPUT",
}

export interface CompanyType {
  id: number;
  description: string;
}

export interface StepButtonsProps<TValues = any> {
  loading?: boolean;
  nextDisabled?: boolean;
  prevDisabled?: boolean;
  wrapperClassName?: string;
  currentStep: number;
  steps: string[];
  onPrevClick: () => void;
  onNextClick?: (values?: Partial<TValues>) => void;
}

export interface CommonRecord {
  id: number;
  name: string;
}

export interface CommonRecords {
  streetTypes: CommonRecord[];
  boxTypes: CommonRecord[];
}

export interface StreetAddress {
  id: number;
  streetTypeID: number;
  countryID: number;
  unitNumber: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  state: string;
  postcode: string;
}

export interface Document {
  name: string;
  awsFileID: string;
  createdAt: string;
  id: number;
}

export type ShowNotification = (args: {
  type: "success" | "info" | "error" | "warning";
  message: string;
  messageValues?: Record<string, { value: string; translate?: boolean }>;
  description?: string;
  descriptionValues?: Record<string, string>;
}) => void;

export interface RcFile extends File {
  uid: string;
}

export interface Jurisdiction {
  id: number;
  name: string;
}

export interface Status {
  id: 1 | 2 | 3 | 4 | 5 | 6;
  name: string;
}

export interface CompanyIncorporation {
  id: number;
  companyID: number;
  nameIdentical: number;
  nameReserved: number;
  domainName: boolean;
  registeredAgentPlan: boolean;
}

export interface Personal {
  dateOfBirth: Date | null;
  email: string | null;
  firstName: string | null;
  id: number;
  individualID: number | null;
  lastName: string | null;
  middleName: string | null;
  nationalityID: number | null;
  placeOfBirthCity: string | null;
  placeOfBirthCountryID: number | null;
}

export interface Company
  extends Omit<CompanyIncorporation, "id" | "companyID"> {
  id: number;
  createdAt: Date;
  registeredAt: Date | null;
  submittedAt: Date | null;
  jurisdictionID: number;
  distributionID: number;
  name: string;
  acn?: string;
  removedAt: Date | null;
  updatedAt: Date | null;
  selfManagedSuperFund: number;
  statusID: Status["id"];
  status: string;
  typeID: number;
  type: string;
  registeredOfficeID?: number;
  principalPlaceOfBusinessID?: number;
  clientCognitoID: number;
}

export interface InitialData {
  client: Client;
  account: Account;
}

export interface SelectOption {
  value: any;
  children: string | ReactElement;
}

export interface Address {
  countryID: number;
  country: string;
  streetTypeID: number;
  streetType: string;
  id: number;
  postcode: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  suburb: string | null;
  unitNumber: string | null;
  boxTypeID: number | null;
  boxType: string | null;
  boxNumber: string | null;
}

export interface Widgets {
  address: Address | null;
}

export type WidgetParentRoute = "account";

export type WidgetChildRoute = keyof Widgets;

export interface WidgetChildProps {
  handleSave: (
    values: {
      name: WidgetChildRoute;
      data: Widgets; // FormData comes from poa. Unfortunately it is not typed
    }[],
  ) => Promise<Array<Widgets[WidgetChildRoute]>>;
  widgets: Widgets;
  saving: boolean;
  parentID: number;
  inProcess: Array<WidgetChildRoute>;
}

export interface Account {
  businessName: string;
  stripeID: string;
  name: string;
  countryID: number;
  email: string;
  id: number;
  registeredAgentNumber: string;
  slug: string;
  telephone: string;
  address: Address | null;
}

export interface Admin {
  cognitoID: string;
  createdAt: Date;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  updatedAt: Date | null;
}

export interface Client {
  accountID: number;
  currentAddressID?: number;
  cognitoID: string;
  createdAt: Date;
  email: string;
  firstName: string;
  id: number;
  lastName: string;
}

export interface Country {
  deleted: number | null;
  full_name: string;
  id: number;
  iso_alpha_2: string;
  iso_alpha_3: string;
  priority: number | null;
  restricted: number;
  textContentId: number | null;
  un_code: string | null;
}

export interface Languages {
  id: string;
  name: string | null;
}

export interface ApiArgs {
  apiName: ApiNames;
  path?: string;
  options?: {
    headers?: Record<string, string>;
    queryParams?: Record<string, any>;
    body?: Record<string, any>;
  };
}

/**
 * Type representing an operation that can be canceled.
 *
 * @internal
 */
export interface Operation<Response> {
  response: Promise<Response>;
  cancel(abortMessage?: string): void;
}

interface ResponsePayload {
  blob(): Promise<Blob>;
  json(): Promise<DocumentType>;
  text(): Promise<string>;
}

export interface QueryKeys {
  streetTypes: "streetTypes";
  boxTypes: "boxTypes";
  countries: "countries";
  jurisdictions: "jurisdictions";
  company: "company";
  companyTypes: "companyTypes";
  companyDocuments: "companyDocuments";
  companyList: "companyList";
  companyPayment: "companyPayment";
  documents: "documents";
  clientAddresses: "clientAddresses";
  clientIndividuals: "clientIndividuals";
  officeholders: "officeholders";
  officeholderTypes: "officeholderTypes";
  shareholders: "shareholders";
  shareholdersType: "shareholdersType";
  companyShareCapital: "companyShareCapital";
  shareClasses: "shareClasses";
  documentDistributions: "documentDistributions";
  phone: "phone";
  registeredOffice: "registeredOffice";
  principalPlaceOfBusiness: "principalPlaceOfBusiness";
  officeholderAddress: "officeholderAddress";
}

/**
 * Basic response type of REST API.
 *
 * @internal
 */
export interface RestApiResponse {
  body: ResponsePayload;
  statusCode: number;
  headers: Record<string, string>;
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
// eslint-disable-next-line
export type XOR<T, U> = T | U extends object
  ? (Without<T, U> & U) | (Without<U, T> & T)
  : T | U;
