import { FC, useState } from "react";
import {
  StepButtons,
  Alert,
  FormattedMessage,
  Row,
  Button,
  List,
} from "components";
import { AddNewDrawer } from "./components";
import { RemoveIcon, OfficeholdersTable, StepInstructions } from "../shared";
import { useCompanyHolders } from "hooks";
import * as duck from "../../duck";
import { OfficeholderType } from "types";

const Officeholders: FC<duck.Types.StepProps> = ({ company, ...props }) => {
  const [drawerOpen, toggleDrawerOpen] = useState(false);
  const { data, isLoading, queryKey } = useCompanyHolders({
    companyID: company.id,
    subRoute: "officeholders",
  });

  const noDirectors = !data.filter(
    (item) => item.typeID === OfficeholderType.Director,
  ).length;

  const noPublicOfficer = !data.some(
    (item) => item.typeID === OfficeholderType.PublicOfficer,
  );

  return (
    <>
      <AddNewDrawer
        officeholders={data}
        companyID={company.id}
        open={drawerOpen}
        onClose={() => toggleDrawerOpen(false)}
        queryKey={queryKey}
      />
      <StepInstructions
        currentStep={props.currentStep}
        title="company.thirdStep.title"
        description="company.thirdStep.description"
      />
      <Row justify="end" style={{ marginBottom: 14 }}>
        <Button type="primary" onClick={() => toggleDrawerOpen(true)}>
          Add Officeholder
        </Button>
      </Row>
      <OfficeholdersTable
        isLoading={isLoading}
        data={data}
        actionsColumn={[
          {
            title: "common.actions",
            dataIndex: "id",
            render: ({ value }: any) => (
              <RemoveIcon
                companyID={company.id}
                itemID={value}
                subRoute="officeholders"
                queryKeys={[queryKey]}
              />
            ),
          },
        ]}
      />
      <List
        size="small"
        dataSource={[
          {
            title: "company.thirdStep.oneOrMoreDirector",
            valid: !noDirectors,
          },
          {
            title: "company.thirdStep.zeroOrOneSecretary",
            valid: true,
          },
          {
            title: "company.thirdStep.onePublicOfficer",
            valid: !noPublicOfficer,
          },
        ]}
        renderItem={({ title, valid }) => (
          <List.Item>
            <Alert
              type={valid ? "success" : "error"}
              message={<FormattedMessage id={title} />}
            />
          </List.Item>
        )}
      />
      <StepButtons
        {...props}
        nextDisabled={isLoading || noDirectors || noPublicOfficer}
      />
    </>
  );
};

export default Officeholders;
