import { FC } from "react";
import StatusTag from "../status-tag";
import ExtendedTable from "../extended-table";
import { NavLink } from "react-router-dom";
import { routes } from "configs";
import { READABLE_DATE_TIME_FORMAT } from "consts";
import dayjs from "dayjs";
import { getQueryKey } from "utils";
import _uniqBy from "lodash/uniqBy";
import { useQuery, useSelector } from "hooks";
import { ColumnFilterInputTypes, Company } from "types";

const CompanyTable: FC = () => {
  const clientID = useSelector((state) => state.auth.client?.id);
  const { data, isLoading } = useQuery<Company[]>({
    apiName: "companies",
    queryKey: getQueryKey("companyList", clientID),
  });

  return (
    <ExtendedTable<Company>
      loading={isLoading}
      dataSource={data}
      columns={[
        // {
        //   title: "common.id",
        //   dataIndex: "id",
        //   render: ({ value: id }) => (
        //     <NavLink to={routes.company.view({ id })}>{id}</NavLink>
        //   ),
        // },
        {
          title: "company.companyTable.name",
          dataIndex: "name",
          render: ({ value, record: { id } }) => (
            <NavLink to={routes.company.view({ id })}>
              <strong>{value}</strong>
            </NavLink>
          ),
        },
        {
          title: "company.acn",
          dataIndex: "acn",
        },
        {
          title: "common.status",
          dataIndex: "status",
          filters: _uniqBy(data, "status").map((item) => ({
            value: item.statusID,
            text: item.status,
          })),
          onFilter: (value, record) =>
            value.toString() === record.statusID.toString(),
          render: ({ record: { statusID, status } }) => (
            <StatusTag id={statusID} name={status} />
          ),
        },
        {
          title: "date.createdAt",
          dataIndex: "createdAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) => dayjs(value).format(READABLE_DATE_TIME_FORMAT),
        },
        {
          title: "date.submittedAt",
          dataIndex: "submittedAt",
          filterInputType: ColumnFilterInputTypes.DATE_RANGE,
          render: ({ value }) =>
            value && dayjs(value).format(READABLE_DATE_TIME_FORMAT),
        },
      ]}
    />
  );
};

export default CompanyTable;
