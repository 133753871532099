import { FC } from "react";
import { AsyncSelect, Button, Flex, Form, Icons } from "components";
import { Personal } from "types";
import { getQueryKey } from "utils";
import { createUseStyles } from "react-jss";
import { useSelector } from "hooks";

const useStyles = createUseStyles({
  individualSelect: {
    width: "85%",
  },
});

const IndividualSelect: FC<{ onAddClick: () => void }> = ({ onAddClick }) => {
  const clientID = useSelector((state) => state.auth.client?.id as number);
  const classes = useStyles();

  return (
    <Flex justify="space-between" align="end" gap={8}>
      <AsyncSelect<Personal>
        required
        preserve={false}
        name="individualID"
        className={classes.individualSelect}
        label="common.individual"
        apiName="client"
        path={`/${clientID}/individuals`}
        queryKey={getQueryKey("clientIndividuals", clientID)}
        getOptionProps={(opt) => ({
          value: opt.id,
          children: `${opt.firstName} ${opt.lastName} ${opt.middleName || ""}`,
        })}
      />
      <Form.Item label={<p hidden>click</p>}>
        <Button icon={<Icons.PlusOutlined />} onClick={onAddClick} />
      </Form.Item>
    </Flex>
  );
};

export default IndividualSelect;
