import { FC } from "react";
import { Typography } from "components";
import { formatToCurrency } from "utils";
import { createUseStyles } from "react-jss";
import { COLORS } from "consts";

interface TotalPriceProps {
  total: number;
}

const useStyles = createUseStyles({
  total: {
    fontSize: 32,
    marginBottom: "0 !important",
    width: "100%",
    fontWeight: "bold",
    textAlign: "center",
    background: `linear-gradient(90deg, ${COLORS.blue[4]}, ${COLORS.purple[4]})`,
    backgroundClip: "text",
    textFillColor: "transparent",
  },
});

const TotalPrice: FC<TotalPriceProps> = ({ total }) => {
  const classes = useStyles();

  return (
    <Typography.Paragraph className={classes.total}>
      Total: {formatToCurrency(total)}
    </Typography.Paragraph>
  );
};

export default TotalPrice;
