import { FC, ReactNode } from "react";
import SubmitButton from "../submit-button";
import GoogleAutocomplete from "../google-autocomplete";
import { FormattedMessage } from "react-intl";
import { Form, Typography, Button, Drawer, FormInstance } from "antd";
import { getQueryKey } from "utils";
import { Address } from "types";
import { useMutation } from "hooks";
import { FormLayout, DrawerLayout } from "./components";

export interface AddressProps {
  clientID: number;
  showTitle?: boolean;
  initialValues?: Address;
  onSuccess: (newData: Address) => void;
  drawerProps?: {
    open: boolean;
    onClose: () => void;
  };
}

interface FromComponentProps {
  form?: FormInstance;
  showTitle?: boolean;
  children?: ReactNode;
  onFinish: (values: any) => void;
}

const FormComponent: FC<FromComponentProps> = ({
  form,
  showTitle,
  children,
  onFinish,
}) => (
  <Form form={form} layout="vertical" preserve={false} onFinish={onFinish}>
    {showTitle && (
      <Typography.Title level={5} style={{ marginTop: 0 }}>
        <FormattedMessage id="address.title" />
      </Typography.Title>
    )}
    <Form.Item label={<FormattedMessage id="address.location" />} shouldUpdate>
      {({ setFieldsValue }) => (
        <GoogleAutocomplete
          onChange={(addresses) => {
            setFieldsValue(addresses);
          }}
        />
      )}
    </Form.Item>

    {children}
  </Form>
);

const AddressTypeComponent: FC<AddressProps> = ({
  drawerProps,
  clientID,
  showTitle = true,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const { isPending, mutate } = useMutation<Address>({
    invalidateQueries: [getQueryKey("clientAddresses", clientID)],
    method: "post",
    apiName: "client",
    path: `/${clientID}/address`,
    onSuccess: (newData) => {
      onSuccess(newData);
    },
  });

  if (!drawerProps) {
    return (
      <FormComponent showTitle={showTitle} onFinish={mutate}>
        <FormLayout />
        <SubmitButton loading={isPending} />
      </FormComponent>
    );
  }

  return (
    <Drawer
      {...drawerProps}
      width="500"
      destroyOnClose
      title={<FormattedMessage id="address.title" />}
      footer={
        <Button
          type="primary"
          loading={isPending}
          onClick={() => form.submit()}
        >
          Submit
        </Button>
      }
    >
      <FormComponent form={form} onFinish={mutate}>
        <DrawerLayout />
      </FormComponent>
    </Drawer>
  );
};

export default AddressTypeComponent;
