import { ExtendedTable, FormattedMessage, Typography } from "components";
import { Types } from "../../../duck";

const OfficeholderTable = <Data,>({
  data,
  isLoading,
  showTitle,
  actionsColumn = [],
}: Types.CommonTableProps<Data>) => {
  return (
    <>
      {showTitle && (
        <Typography.Title level={5}>
          <FormattedMessage id="common.shareholders" />
        </Typography.Title>
      )}
      <ExtendedTable
        loading={isLoading}
        dataSource={data}
        columns={[
          {
            title: "common.name",
            dataIndex: "individual",
            render: ({ record }: any) => record.individual || record.company,
          },
          {
            title: "common.type",
            dataIndex: "type",
          },
          {
            title: "common.beneficiallyHeld",
            dataIndex: "beneficiallyHeld",
            render: ({ value }) => value && "+",
          },
          {
            title: "company.shareClass",
            dataIndex: "shareClass",
          },
          {
            title: "company.sharesIssued",
            dataIndex: "sharesIssued",
          },
          ...actionsColumn,
        ]}
      />
    </>
  );
};

export default OfficeholderTable;
