import { FC } from "react";
import { Flex, Button, Form, Typography } from "antd";
import InputSelect from "../basic";
import { PlusOutlined } from "@ant-design/icons";
import { removeExSpaces } from "utils";
import { useClientAddresses } from "hooks";
import { createUseStyles } from "react-jss";

interface AddressSelectProps {
  name: string;
  justify?: "space-between";
  className?: string;
  onCreate: () => void;
}

const useStyles = createUseStyles({
  address: {
    marginBottom: 24,
  },
});

const AddressSelect: FC<AddressSelectProps> = ({
  name,
  className,
  justify,
  onCreate,
}) => {
  const classes = useStyles();
  const { data, isLoading } = useClientAddresses();

  return (
    <>
      <Flex gap={8} align="end" justify={justify}>
        <InputSelect
          required
          name={name}
          className={className}
          label="address.select"
          initialLoading={isLoading}
          options={data}
          filterOption={(input, opt) => {
            const value = input.toLowerCase();
            // TODO Refactor InputSelect(remove getOptionProps, add optionRender func, etc
            // eslint-disable-next-line
            // @ts-ignore
            return opt.children.props.children.some((item) => {
              if (!item) {
                return false;
              }

              return item.toLowerCase().indexOf(value) !== -1;
            });
          }}
          getOptionProps={(opt) => ({
            value: opt.id,
            children: (
              <>
                {opt.unitNumber && opt.unitNumber}
                &nbsp;
                {opt.streetNumber} {opt.streetName} {opt.streetType}{" "}
                {opt.suburb} {opt.state} {opt.country}
              </>
            ),
          })}
        />
        <Form.Item label={<p hidden>click</p>}>
          <Button icon={<PlusOutlined />} onClick={onCreate} />
        </Form.Item>
      </Flex>
      <Form.Item dependencies={[name]} noStyle>
        {({ getFieldValue }) => {
          const addressID = getFieldValue(name);
          const address = data.find((item) => item.id === addressID);

          if (!address) {
            return null;
          }

          const addressString1 = removeExSpaces(
            `${address.streetNumber} ${address.streetName} ${address.streetType}`,
          );
          const addressString2 = removeExSpaces(
            `${address.suburb} ${address.state} ${address.postcode}`,
          );
          return (
            <Typography.Paragraph className={classes.address}>
              <Typography.Text strong>Selected Address:</Typography.Text>
              <br />
              {address.unitNumber ? (
                <span>{address.unitNumber}&nbsp;/&nbsp;</span>
              ) : null}
              {addressString1}
              <br />
              {addressString2}
              <br />
              {address.country}
            </Typography.Paragraph>
          );
        }}
      </Form.Item>
    </>
  );
};

export default AddressSelect;
