import { FC, useState } from "react";
import { StepButtons, Button, FormattedMessage, Row } from "components";
import { AddNewDrawer } from "./components";
import { RemoveIcon, ShareholdersTable, StepInstructions } from "../shared";
import { useCompanyHolders } from "hooks";
import * as duck from "../../duck";

const Shareholders: FC<duck.Types.StepProps> = ({ company, ...props }) => {
  const [state, setState] = useState<{
    drawerOpen: boolean;
  }>({
    drawerOpen: false,
  });
  const { data, isLoading, queryKey } = useCompanyHolders({
    companyID: company.id,
    subRoute: "shareholders",
  });

  return (
    <div>
      <StepInstructions
        currentStep={props.currentStep}
        title="company.fiveStep.title"
        description="company.fiveStep.description"
        descriptionValues={{
          title: <FormattedMessage id="common.shareholders" />,
        }}
      />
      <AddNewDrawer
        shareholders={data}
        companyID={company.id}
        open={state.drawerOpen}
        queryKey={queryKey}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            drawerOpen: false,
          }));
        }}
      />
      <Row justify="end" style={{ marginBottom: 14 }}>
        <Button
          type="primary"
          onClick={() => {
            setState((prevState) => ({
              ...prevState,
              drawerOpen: true,
            }));
          }}
        >
          Add shareholder
        </Button>
      </Row>
      <ShareholdersTable
        isLoading={isLoading}
        data={data}
        actionsColumn={[
          {
            title: "common.actions",
            dataIndex: "id",
            render: ({ value }: any) => (
              <RemoveIcon
                companyID={company.id}
                itemID={value}
                subRoute="shareholders"
                queryKeys={[queryKey]}
              />
            ),
          },
        ]}
      />
      <StepButtons {...props} nextDisabled={!data.length || isLoading} />
    </div>
  );
};

export default Shareholders;
