import { FC } from "react";
import {
  Button,
  Col,
  Divider,
  FormattedMessage,
  Image,
  Row,
  Flex,
  Typography,
  Form,
  InputText,
} from "components";
import { dashboardLogo } from "assets";
import { useNavigate, useMutation } from "hooks";
import { getQueryKey } from "utils";
import { routes } from "configs";
import { Company } from "types";

const CreateCompany: FC = () => {
  const navigate = useNavigate();
  const mutation = useMutation<Company, Pick<Company, "name">>({
    apiName: "companies",
    method: "post",
    invalidateQueries: [getQueryKey("companyList")],
    onSuccess: (company) => {
      navigate(
        routes.company.view({
          id: company.id,
        }),
      );
    },
  });

  return (
    <>
      <Row>
        <Col xs={24} sm={15} md={15} lg={15}>
          <Typography.Title level={4}>
            <FormattedMessage id="company.splashTitle" />
          </Typography.Title>
          <Divider />
          <Typography.Paragraph>
            <FormattedMessage
              id="company.splashDescription"
              values={{
                br: (
                  <>
                    <br />
                    <br />
                  </>
                ),
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </Typography.Paragraph>
          <Typography.Paragraph>
            <em>
              Note: You can check your desired company name is not yet taken
              at:&nbsp;
              <a
                href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/SearchRegisters.jspx"
                target="_blank"
                rel="noreferrer"
              >
                ASIC Company Name Search
              </a>
            </em>
          </Typography.Paragraph>
        </Col>
        <Col xs={24} sm={9} md={9} lg={9}>
          <Image src={dashboardLogo} preview={false} />
        </Col>
      </Row>
      <Divider />
      <Form
        layout="vertical"
        onFinish={({ name }) => {
          mutation.mutate({
            name,
          });
        }}
      >
        <Flex gap={8} align="center">
          <InputText
            required
            placeholder="Type it here"
            name="name"
            label="company.newName"
            className="animate__animated animate__fadeInRight"
          />
          <Form.Item label={<span>&nbsp;</span>}>
            <Button
              type="primary"
              htmlType="submit"
              loading={mutation.isPending}
              className="animate__animated animate__fadeInLeft"
            >
              <FormattedMessage id="button.start" />
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </>
  );
};

export default CreateCompany;
