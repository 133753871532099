import { ReactNode } from "react";
import { Empty, Skeleton as AntSkeleton } from "components";
import { useParams, useSelector, useQuery } from "hooks";
import { ApiNames, Params } from "types";

const viewHOC =
  <TRecord = any,>({
    Component,
    pathFn,
    queryKeyFn,
    apiName,
    skeleton = <AntSkeleton active loading />,
  }: {
    Component: any;
    queryKeyFn: (params: Params<"id">) => Array<string | number>;
    apiName: ApiNames;
    pathFn?: (args: { params: Params<"id">; accountID: number }) => string;
    skeleton?: ReactNode;
  }) =>
  (props: any) => {
    const params = useParams<"id">();
    const accountID = useSelector((root) => root.auth.account?.id as number);
    const queryKey = queryKeyFn(params);

    const { data, isLoading } = useQuery<TRecord>({
      queryKey,
      apiName,
      path: pathFn?.({ params, accountID }),
    });

    if (isLoading) {
      return skeleton as any;
    }

    if (!data && !isLoading) {
      return <Empty />;
    }

    return <Component {...props} record={data} queryKey={queryKey} />;
  };

export default viewHOC;
