import { FC, useState } from "react";
import { OfficeholderAddress } from "./components";
import { OfficeholdersTable } from "../../../shared";
import { useCompanyHolders } from "hooks";
import { TabProps } from "../../types";

const Officeholders: FC<TabProps> = ({ company }) => {
  const [expandedRowID, setExpandedRowID] = useState<number>();
  const { data, isLoading } = useCompanyHolders({
    companyID: company.id,
    subRoute: "officeholders",
  });

  return (
    <OfficeholdersTable
      isLoading={isLoading}
      data={data}
      expandable={{
        expandedRowKeys: expandedRowID ? [expandedRowID] : [],
        onExpand: (expanded, { id }) => {
          setExpandedRowID(expanded ? id : undefined);
        },
        expandedRowRender: () => (
          <OfficeholderAddress
            residentAddressID={
              data.find((item) => item.id === expandedRowID)?.residentAddressID
            }
          />
        ),
      }}
    />
  );
};

export default Officeholders;
